// src/App.js

import React, { useState, useEffect } from 'react';
import './App.css';

// Список цветов и соответствующих им вкусов
const COLORS = [
  {
    name: 'Коричневый',
    hex: '#8B4513',
    flavors: ['печень и лук', 'капучино'],
  },
  {
    name: 'Желтый',
    hex: '#FFFF00',
    flavors: ['тухлое яйцо', 'попкорн с маслом'],
  },
  {
    name: 'Оранжевый',
    hex: '#FFA500',
    flavors: ['рвота', 'персик'],
  },
  {
    name: 'Зеленый',
    hex: '#008000',
    flavors: ['сопли', 'груша'],
  },
  {
    name: 'Бежевый',
    hex: '#F5F5DC',
    flavors: ['вонючие носки', 'тутти фрути'],
  },
  {
    name: 'Красный',
    hex: '#FF0000',
    flavors: ['старый бинт', 'гранат'],
  },
  {
    name: 'Синий',
    hex: '#0000FF',
    flavors: ['зубная паста', 'синие ягоды'],
  },
  {
    name: 'Сливочный',
    hex: '#FFFDD0',
    flavors: ['жук вонючка', 'жаренный маршмеллоу'],
  },
  {
    name: 'Белый',
    hex: '#FFFFFF',
    flavors: ['помои', 'торт на день рождения'],
  },
  {
    name: 'Цвет картофеля',
    hex: '#D2B48C', // Пример цвета картофеля
    flavors: ['тухлая рыба', 'клубнично-банановый коктейль'],
  },
];

function App() {
  const [currentColor, setCurrentColor] = useState(getRandomColor());
  const [displayFlavors, setDisplayFlavors] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);

  // Функция для получения случайного цвета из списка
  function getRandomColor() {
    const randomIndex = Math.floor(Math.random() * COLORS.length);
    return COLORS[randomIndex];
  }

  function easeInOutQuint(x) {
    return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
  }

  // Обработчик нажатия
  const handlePress = () => {
    setDisplayFlavors(true);
    // Перемешиваем массив цветов
    const shuffledColors = shuffleArray(COLORS);
    // Генерируем случайное число
    let randomNumber = Math.floor(5 + Math.random() * 1000);
    // Выбираем индекс по модулю длины массива
    let selectedIndex = randomNumber % shuffledColors.length;
    // Через 3 секунды устанавливаем выбранный цвет
    let count = 0;
    let lastValue = 0;
    let interval = setInterval(() => {
      selectedIndex = randomNumber % shuffledColors.length;
      randomNumber++;
      count++;
      setSelectedColor(shuffledColors[selectedIndex]);
      setCurrentColor(shuffledColors[selectedIndex]);
      setDisplayFlavors(false);
    }, 100);
    setTimeout(() => {
      clearInterval(interval);
    }, 3000);
  };

  // Функция для перемешивания массива (Фишер-Йетс)
  function shuffleArray(array) {
    const arr = [...array];
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  }

  // Эффект для установки начального цвета
  useEffect(() => {
    setCurrentColor(getRandomColor());
  }, []);

  return (
    <div
      className="App"
      style={{
        backgroundColor: currentColor.hex,
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'background-color 0.5s',
        padding: '20px',
        boxSizing: 'border-box',
      }}
      onClick={handlePress}
    >

      {selectedColor && (
        <div 
          style={{ 
            marginTop: '20px', 
            color: getContrastingTextColor(selectedColor.hex), 
            textAlign: 'center' 
          }}
        >
          <div style={{ fontSize: '1.5em' }}>
            <p><b>{selectedColor.flavors[0]}</b></p>
            <p style={{ margin: '10px 0' }}>или</p>
            <p><b>{selectedColor.flavors[1]}</b></p>
          </div>
        </div>
      )}
    </div>
  );
}

// Функция для определения контрастного цвета текста
function getContrastingTextColor(hex) {
  // Удаляем #, если есть
  hex = hex.replace('#', '');
  // Преобразуем в RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  // Вычисляем яркость
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  // Возвращаем черный или белый
  return brightness > 125 ? '#000000' : '#FFFFFF';
}

export default App;
